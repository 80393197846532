import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/productions",
    name: "productions",
    component: () => import("../views/ProductionsView.vue"),
    alias: "/produktionen",
  },
  {
    path: "/production/:link",
    name: "production",
    component: () => import("../views/ProductionView.vue"),
    alias: "/produktion/:link",
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/AboutView.vue"),
    alias: ["/firmengeschichte", "/about", "/ueberuns"],
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
    alias: "/kontakt",
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("../views/ImprintView.vue"),
    alias: "/impressum",
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
    alias: "/datenschutz",
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/NewsView.vue"),
    alias: "/aktuelles",
  },
  {
    path: "/newsarchive",
    name: "newsarchive",
    component: () => import("../views/NewsArchiveView.vue"),
    alias: ["/artikelarchiv", "/newsarchiv"],
  },
  {
    // Catch-all Route für alle anderen Pfade
    path: "*",
    redirect: "/", // Umleitung auf Startseite
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    // Wenn ein Anchor-Link vorhanden ist, zum Element scrollen
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    // Standardmäßig zur oberen Position der Seite scrollen
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Close MobileNav
  store.commit("updateNavOpen", false);
  next();
});

export default router;
