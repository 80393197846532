import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    production: "production",
    productions: "productions",
    coproduction: "Co-production",
    coproductions: "co-productions",
    cinema: "cinema",
    tv: "TV",
    all: "all",
    author: "Author",
    news: "news",
    article: "article",
    history: "history",
    contact: "Contact",
    imprint: "Imprint",
    privacypolicy: "privacy policy",
    privacylink: "privacy",
    toMovie: "To the movie",
    readmore: "More",
    munich: "Munich",
    archive: "Archive",
    close: "close",
    premiere: "premiere",
    originalTitle: "original title",
    autographRequests: "Autograph requests",
    pressRequests: "PR- / event- and interview requests",
    stamping: "Please enclose a sufficiently stamped and addressed envelope.",
    autographInfo:
      "Please understand that personal dedications and/or greetings for special occasions (wedding, birthday, etc.) cannot be processed due to time constraints.",
    operator: "Site owner",
    phone: "Phone",
    email: "Email",
    director: "Managing Director",
    comreg: "Commercial Register",
    vat: "VAT ID",
    vatInfo:
      "Sales tax identification number according to Sect. 27 a of the Sales Tax Law",
    responsible: "Person responsible for editorial",
    register: "Commercial Register",
    k_work: "Projectmanagement & Content",
    s_work: "Web Development, Usability & UX-Design",
    realisation: "Realisation website",
    exploreTrailer: "Explore Trailer",
    exploreProduction: "Explore Production",
    regCourt: "Registration court",
    repBy: "Represented by",
    redResp: "Person responsible for editorial",
    dispRes:
      "Dispute resolution proceedings in front of a consumer arbitration board",
    dispResInfo:
      "We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.",
  },
  de: {
    production: "Produktion",
    productions: "Produktionen",
    coproduction: "Co-Produktion",
    coproductions: "Co-Produktionen",
    cinema: "Kino",
    tv: "TV",
    all: "Alle",
    author: "Autor",
    news: "News",
    article: "Artikel",
    history: "Firmengeschichte",
    contact: "Kontakt",
    imprint: "Impressum",
    privacypolicy: "Datenschutz",
    privacylink: "datenschutz",
    toMovie: "Zum Film",
    readmore: "weiterlesen",
    munich: "München",
    archive: "Archiv",
    close: "Schliessen",
    premiere: "Kinostart",
    originalTitle: "Originaltitel",
    autographRequests: "Autogrammanfragen",
    pressRequests: "Presse- / Event- und Interviewanfragen",
    stamping:
      "Bitte einen ausreichend frankierten und adressierten Rückumschlag beilegen.",
    autographInfo:
      "Bitte haben Sie Verständnis dafür, dass persönliche Widmungen und/oder Grüße für besondere Anlässe (Hochzeit, Geburtstag, etc.) aus zeitlichen Gründen nicht bearbeitet werden können.",
    operator: "Betreiber",
    phone: "Telefon",
    email: "E-Mail",
    director: "Geschäftsführer",
    comreg: "Handelsregister",
    vat: "Umsatzsteueridentifikation",
    vatInfo:
      "Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz",
    responsible: "Verantwortlich",
    register: "Amtsgericht",
    k_work: "Projektmanagement & Content",
    s_work: "Webentwicklung, Usability & UX-Design",
    realisation: "Realisation Webseite",
    exploreTrailer: "Trailer ansehen",
    exploreProduction: "zur Produktion",
    regCourt: "Registergericht",
    repBy: "Vertreten durch",
    redResp: "Redaktionell verantwortlich",
    dispRes: "Verbraucherstreitbeilegung / Universalschlichtungsstelle",
    dispResInfo:
      "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
  },
};

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});

export default i18n;
